import { SlideNumber, initializeSliderOnView } from './slide-func.js';

document.addEventListener("DOMContentLoaded", function () {
  $(function () {
    const servicesSliderConfig = {
      perPage: 5,
      perMove: 5,
      arrows: true,
      pagination: true,
      gap: "10px",
      interval: 6000,
      breakpoints: {
        991: {
          perPage: 4,
          perMove: 4,
        },
        767: {
          pagination: false,
          perPage: 3,
          perMove: 3,
        },
        479: {
          pagination: false,
          perPage: 2,
          perMove: 2,
        },
      },
    };

    // Inicjalizacja slidera dla komponentu 
    initializeSliderOnView('.specialServices', '[data-services] .servicesSlider', servicesSliderConfig, SlideNumber);
  });
});